html {
  background-color: $viewport-background-color;
  box-sizing: border-box;
}

*,
*::before,
*::after {
  box-sizing: inherit;
}

*:focus {
  outline: none
}

html,
body {
  height: 100%;
}

body {
  margin: 0;
}

.brenda{
  border:1px solid green;
}
