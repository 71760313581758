
.boxCircular{  
  position: fixed;
  top: calc(50% - 45px);
  left: calc(50% - 45px);
  width: 130px;
  height: 140px;
  padding-top:15px;
  background: #ffffffba;
  border-radius:5px;
  font-size: 11px;
  text-align: center;
} 
.circularP{
  margin-bottom:20px
}

.OutboxCircular2{
  border: 1px solid none;
}

.boxCircular2{  
  position: relative;
  top: calc(50% - 45px);
  left: calc(50% - 45px);
  width: 130px;
  height: 140px;
  padding-top:15px;
  background: #ffffffba;
  border-radius:5px;
  font-size: 11px;
  text-align: center;
  margin:30px 0px;
} 

.boxNoRegister{  
  padding: 25px;
  background: #f5f5f5;
  color: #a9a6a6;
  border-radius: 5px;
  font-size: 15px;
  text-align: center;
} 
.icoNoRegister{
  margin-bottom:10px;
}


