.swal2-container {
    z-index: 1300!important;
}
@media (max-width: 959.95px){
    .MuiTableCell-root {
        height: 65px!important;
        overflow: hidden;
        text-overflow: ellipsis;
    }
}

@media (max-width: 959.95px){
    .sizeRadarMobile{
        height: 300px;
        width: 600px;
    }
}

@media (max-width: 959.95px){
        .fixBugMobileContainerGrid{
            display: block!important;
        }
    }

@media (max-width: 533px){    
        .recharts-wrapper{
            width: 400px!important;
            overflow: auto!important;
        }
    }

@media (max-width: 385px){    
        .recharts-wrapper{
            width: 320px!important;
            overflow: auto!important;
        }
    }
        

    