@import "./../mixins";
$sm: "(max-width: 600px)";

.bodyForm {
  position: relative;
  background: $white;
  padding: 16px;
  @media #{$sm} {
    padding: 15px 10px;
  }
  max-height: 450px;
  overflow: auto;
}
.buttonArea {
  background: material-color('grey', '100');
  position: relative;
  bottom: 0;
  left: 0;
  width: 100%;
  text-align: right;
  padding: 8px 24px;
  button{
    margin-right: 5px;
  }
}
.paperForm{
  padding: 15px;
  margin-bottom: 20px;
}
.paperContent{
  padding-top: 15px;
}

.btnew{
  float:right;
  right:10px;
  margin-top: -110px; 
}
.btnew::after{
  clear:both;
}

.fixshrink{
  background:white;
  padding:0px 18px 0px 2px;
}